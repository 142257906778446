import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../assets/css/SecurityCompliance.css';
import { securityFeatures } from '../../constants/index';

const SecurityCompliance = () => {
    return (
        <section id="security-compliance" className="security-compliance-section py-5">
            <Container>
                <Row className="align-items-center">
                    {/* Left Column for Title and Description */}
                    <Col md={5} className="text-md-start text-center">
                        <h2 className="display-5 fw-bold">Security & Compliance</h2>
                        <p className="text-muted fs-5 mt-3">Ensuring data protection, regulatory compliance, and transaction security for all clients.</p>
                    </Col>

                    {/* Right Column with Security Features */}
                    <Col md={7}>
                        <Row>
                            {securityFeatures.map((feature, index) => (
                                <Col md={12} key={index} className="mb-4">
                                    <Card className="feature-card border-0 shadow-sm h-100">
                                        <Row className="align-items-center">
                                            <Col xs={3} className="text-center">
                                                <img src={feature.image} alt={feature.title} className="img-fluid" />
                                            </Col>
                                            <Col xs={9}>
                                                <Card.Body>
                                                    <Card.Title className="fs-4 fw-semibold text-dark">{feature.title}</Card.Title>
                                                    <Card.Text className="text-muted">{feature.description}</Card.Text>
                                                </Card.Body>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default SecurityCompliance;
