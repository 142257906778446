import React, { useEffect, useState } from 'react'
import {
    FaBagShopping,
    FaShop,
    FaMagnifyingGlass,
    FaPeopleGroup,
    FaLinesLeaning,
    FaRegFileLines
} from "react-icons/fa6";
import Window from '../Window';
import "./styles/merchants.css";
import BuildMerchants from '../builders/BuildMerchants';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../shared/assets/stores/store';
import { GetAllUsers, AllMerchantTransactions } from '../../shared/assets/slices/adminSlice';
import NumberFormatter from '../../customer/components/NumberFormatter';
import ReactPaginate from 'react-paginate';
import { UserProps } from '../../types/index';
import UserDetailsModal from './UserDetailsModal';

type Props = {}

const MerchantsView = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [merchants, setMerchants] = useState<UserProps[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalMerchants, setTotalMerchants] = useState<number>(0);
    const [totalMerchantTransactions, setTotalMerchantTransactions] = useState<number>(0);
    const admindata = useSelector((state: RootState) => state.admin);
    const { access_token } = admindata.getAdmin.data;
    const merchantsPerPage = 5;

    const [selectedMerchant, setSelectedMerchant] = useState<UserProps | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (merchant: UserProps) => {
        setSelectedMerchant(merchant);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedMerchant(null);
        setIsModalOpen(false);
    };


    // Fetch transactions data
    useEffect(() => {
        const fetchTransactions = async () => {
            const response = await dispatch(AllMerchantTransactions());
            const merchantTransactions = response.payload.results.filter((transaction: any) => transaction.user?.user_type === "Merchant");
            setTotalMerchantTransactions(merchantTransactions.length);
        };
        fetchTransactions();
    }, [dispatch]);

    // Fetch merchants data
    useEffect(() => {
        const fetchMerchants = async () => {
            const response = await dispatch(GetAllUsers(access_token));
            const merchantData = response.payload
                .filter((user: any) => user.user_type === "Merchant")
                .sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
            setMerchants(merchantData);
            setTotalMerchants(merchantData.length);
        }
        fetchMerchants();
    }, [dispatch, access_token]);

    // Handle pagination page change
    const handlePageChange = ({ selected }: { selected: number }) => {
        setCurrentPage(selected);
    };

    // Handle search input change
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(0);
    };

    // Filter merchants based on search term
    const filteredMerchants = merchants.filter(merchant =>
        (merchant.business_name && merchant.business_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        merchant.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        merchant.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (merchant.country && merchant.country.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    // Pagination calculations for filtered merchants
    const pageCount = Math.ceil(filteredMerchants.length / merchantsPerPage);
    const offset = currentPage * merchantsPerPage;
    const currentMerchants = filteredMerchants.slice(offset, offset + merchantsPerPage);

    return (
        <div className='merch_sup_cont'>
            <h2 className='merch_title1'>Merchants</h2>
            <div className='merch_overview'>
                <div className='merch_overview_sec'>
                    <FaBagShopping size={30} color='#EA580C' className='merch_overview_icn' />
                    <div>
                        <p className='merch_overview_desc'>Total Merchants</p>
                        <p className='merch_overview_amt'><NumberFormatter amount={totalMerchants} /></p>
                    </div>
                </div>
                <div className='merch_overview_sec'>
                    <FaLinesLeaning size={30} color='#9333EA' className='merch_overview_icn' />
                    <div>
                        <p className='merch_overview_desc'>Total Orders</p>
                        <p className='merch_overview_amt'><NumberFormatter amount={totalMerchantTransactions} /></p>
                    </div>
                </div>
            </div>
            <div className='merch_merch_cont'>
                <div className='merch_search_cont'>
                    <div className='merch_search_input_wrpr'>
                        <input
                            type="search"
                            placeholder="Search by business name, first name, last name, or country"
                            className='merch_search_box'
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <FaMagnifyingGlass size={20} color='#eee' className='merch_search_icn' />
                    </div>
                </div>
                <div className='merch_merch_hd'>
                    <p className='merch_merch_hd_txt'>Business Name</p>
                    <p className='merch_merch_hd_txt'>FirstName</p>
                    <p className='merch_merch_hd_txt'>LastName</p>
                    <p className='merch_merch_hd_txt'>Country</p>
                    <p className='merch_merch_hd_txt'>Action</p>
                </div>
                <div>
                    {currentMerchants.map((merchant) => (
                        <BuildMerchants key={merchant.id} {...merchant} onDetailsClick={() => openModal(merchant)} />
                    ))}
                </div>
            </div>
            {pageCount > 1 && (
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                />
            )}
            <UserDetailsModal
                show={isModalOpen}
                onHide={closeModal}
                user={selectedMerchant}
            />

        </div>
    )
}

const Merchants = () => {
    const content = {
        currentView: <MerchantsView />,
        activeId: 3
    }
    return (
        <Window currentView={content.currentView} activeId={content.activeId} />
    )
}

export default Merchants;
