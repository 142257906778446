import React from 'react';
import { UserProps, BuildMerchantsProps } from '../../types/index';
import './styles/buildMerchants.css';


const BuildMerchant:React.FC<BuildMerchantsProps> = ({id, business_name,
  first_name,
  last_name,
  country,
  onDetailsClick,
}) => {
  return (
      business_name === null ? null : (
        <div className={id % 2 === 0 ? 'merch_sec gray' : 'merch_sec '}>
          <p className='merch_txt'>{business_name}</p>
          <p className='merch_txt'>{first_name}</p>
          <p className='merch_txt'>{last_name}</p>
          <p className='merch_txt'>{country}</p>
          <p className='merch_txt'>
            <p className='merch_action' onClick={onDetailsClick}>Details</p>
          </p>
        </div>)
  )
}

export default BuildMerchant