import { faTools, faGlobe, faHandHoldingHeart, faLandmark, faHandHoldingUsd, faTv, faMobileAlt, faLightbulb, faTrash, faHotel, faPlane, faStethoscope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import papssLogo from '../shared/assets/images/papss.png';
import ubaLogo from '../shared/assets/images/uba.png';
import redTechLogo from '../shared/assets/images/redtech.png';
import coralpayLogo from '../shared/assets/images/coralpay.jpg';
import dojahLogo from '../shared/assets/images/dojah.svg';
import sarepayLogo from '../shared/assets/images/sareppay.png';
import dataEncryption from '../shared/assets/images/data-encryption.svg';
import pciDssCompliance from '../shared/assets/images/pci-dss-compliance.svg';
import fraudPrevention from '../shared/assets/images/fraud-prevention.svg';
import clientLogo1 from '../shared/assets/images/client-1.png';
import clientLogo2 from '../shared/assets/images/client-2.png';
import clientLogo3 from '../shared/assets/images/client-3.png';

export const TopBillers = [
  { name: 'Utilities', icon: faTools, description: 'Utility payments for airtime, data, electricity, and more', url: '/utilities' },
  { name: 'Educational', icon: faGlobe, description: 'Educational payments for schools, exams, and courses', url: '/educational' },
  {
    name: 'Medicals',
    icon: faStethoscope,
    description: 'Payments for healthcare services and treatments',
    url: '/medicals'
  },
  { name: 'Donations', icon: faHandHoldingHeart, description: 'Make donations to various organizations', url: '/donations' },
  { name: 'Embassies', icon: faLandmark, description: 'Payments for embassies and consular services', url: '/embassies' },
  { name: 'Contributions', icon: faHandHoldingUsd, description: 'Local Contributions between groups, clubs, societies and individuals', url: '/contributions' },
];

export const Utilities = [
  { name: 'Airtime', icon: faMobileAlt },
  { name: 'Data', icon: faGlobe },
  { name: 'Electricity', icon: faLightbulb },
  { name: 'TV Subscriptions', icon: faTv },
  { name: 'Waste Management', icon: faTrash },
  { name: 'Hotel Bookings', icon: faHotel },
  { name: 'Flight Bookings', icon: faPlane },
];

export const footerData = {
  quickLinks: [
    { label: 'Privacy Policy', href: '/privacy-policy' },
    { label: 'Terms of Service', href: '/terms-of-service' },
    { label: 'About Us', href: '/about-us' },
    { label: 'Contact', href: '/contact' }
  ],
  contactInfo: {
    email: 'info@ojapay.com',
    phone: '+234 234 567 890',
    address: '3 Crescent St, Lekki Phase 1, Lagos, Nigeria'
  },
  socialLinks: [
    { icon: faFacebook, href: 'https://facebook.com/ojapay' },
    { icon: faTwitter, href: 'https://twitter.com/ojapay' },
    { icon: faInstagram, href: 'https://instagram.com/ojapay' },
    { icon: faLinkedin, href: 'https://linkedin.com/in/ojapay' }
  ]
};

// Array of partners and their information
export const partners = [
  {
    name: 'Uba',
    logo: ubaLogo,
  },
  {
    name: 'Papss',
    logo: papssLogo,
  },
  {
    name: 'RedTech',
    logo: redTechLogo,
  },
  {
    name: 'Coralpay',
    logo: coralpayLogo,
  },
  {
    name: 'Dojah',
    logo: dojahLogo,
  },
  {
    name: 'Sarepay',
    logo: sarepayLogo,
  },
];

export const securityFeatures = [
  {
      image: dataEncryption,
      title: 'Data Encryption',
      description: 'All sensitive data is encrypted to protect customer information from unauthorized access.',
  },
  {
      image: pciDssCompliance,
      title: 'PCI-DSS Compliance',
      description: 'We adhere to PCI-DSS standards to ensure secure handling of payment information.',
  },
  {
      image: fraudPrevention,
      title: 'Fraud Prevention',
      description: 'Our platform uses advanced fraud detection algorithms to safeguard transactions.',
  },
];

export const testimonials = [
  {
      quote: "This platform transformed our payment processing – it's fast, secure, and incredibly user-friendly!",
      name: "Bob Udenze",
      position: "CEO, TechCorp",
      logo: clientLogo1,
  },
  {
      quote: "A seamless experience that has improved our sales conversion by 20% within months!",
      name: "Teni Sobowale",
      position: "Founder, StartSmart",
      logo: clientLogo2,
  },
  {
      quote: "With reliable fraud prevention, we have peace of mind processing transactions globally.",
      name: "Wale Davis",
      position: "Operations Manager, GlobalTrade",
      logo: clientLogo3,
  },
];
