import { setGetUser } from '../../shared/assets/slices/authSlice';
import { Dispatch } from "redux";
import axios from 'axios';
import { UserProps, WalletProps, setUserProps } from '../../types/index';


const HandleSubmit = async (e: any, payload: any, url: string, method: string, setStatus?: any, setMessage?: any, setUser?: any, accessToken?: string, dispatch?: Dispatch, customheaders?: any) => {
  e.preventDefault();
  const body = payload;
  let response: any;
  const headers = customheaders;

  try {
    if (method === "POST") {
      response = await axios.post(url, body, headers);
    } else if (method === "PUT") {
      response = await axios.put(url, body, headers);
    } else if (method === "DELETE") {
      const config = {
        method: 'delete',
        url: url,
        data: {},
        headers,
      };
      response = await axios.delete(url, headers);
    }

    if (response.data.status === 'error') {
      if (setStatus) setStatus(false);
      if (setMessage) setMessage(response.data.response);
      return false;
    } else {
      if (setMessage) setMessage(response.data.response);
      if (setStatus) setStatus(true);
      if (setUser) setUser(response.data.data);
      if (dispatch && response.data.data) {
        const userData: setUserProps = {
          user: response.data.data,
          wallet: [], 
          access_token: '', 
          refresh_token: '', 
        };
        console.log("user data from handlesubmit: ", userData);
        dispatch(setGetUser(userData));
      }
      return true;
    }
  } catch (error: any) {
    console.error(error);
    if (setStatus) setStatus(false);
    return false;
  }
};

export default HandleSubmit;
