import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../assets/css/PartnersIntegrations.css';
import { partners } from '../../constants/index'

const PartnersIntegrations = () => {
    return (
        <section id="partners-integrations" className="py-5 bg-light">
            <Container>
                {/* Header Section */}
                <Row className="text-center mb-5">
                    <Col>
                        <h2 className="display-5 fw-bold">Partners & Integrations</h2>
                        <p className="text-muted fs-5">Seamlessly integrate with leading platforms and enhance interoperability.</p>
                    </Col>
                </Row>
                {/* Partner Grid */}
                <Row className="g-4">
                    {partners.map((partner, index) => (
                        <Col xs={12} sm={6} md={2} key={index}>
                            <Card className="border-0 shadow-sm h-100 text-center p-3">
                                <div className="partner-logo mb-3">
                                    <img src={partner.logo} alt={`${partner.name} logo`} className="img-fluid" style={{ height: '80px' }} />
                                </div>
                                <Card.Body>
                                    <Card.Title className="fs-5 fw-semibold">{partner.name}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default PartnersIntegrations;
