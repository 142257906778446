import React from 'react'
import {useNavigate} from "react-router-dom";
import { TransLogProps } from '../../types/index';
import './styles/buildTransactionLog.css'

const BuildTransactionLog = (transaction: TransLogProps) => {
  const navigate = useNavigate();

  const handleTransactionDetails = (transaction: TransLogProps) => {
      navigate('/admin/TransactionDetails', {state: transaction});
    }

  return (
    Number(transaction.amount) === 0.00  ? null : (<div className={transaction.id % 2 === 0 ? 'trans_sec gray' : 'trans_sec '}>
      <p className='trans_txt'>{transaction.reference.length > 8 ? transaction.reference.slice(0, 8) + '...' : transaction.reference}</p>
      <p className='trans_txt'>{transaction.user 
            ? transaction.user.business_name || `${transaction.user.first_name} ${transaction.user.last_name}` 
            : 'No User Info'
          }</p>
      <p className='trans_txt'>{transaction.payment_type}</p>
      <p className='trans_txt'>{transaction.transaction_type}</p>
      <p className='trans_txt'>{transaction.amount}</p>
      <p className='trans_txt'>{transaction.note}</p>
      <p className='trans_txt'>{new Date(transaction.date).toLocaleString()}</p>
      <p className={transaction.status ? 'trans_txt successful' : 'trans_txt failed'}>
        {transaction.status}
      </p>
      <div>
        <p className='table_title' onClick={()=> handleTransactionDetails(transaction)}>Details</p>
      </div>
    </div>)
  );
};


export default BuildTransactionLog