import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../shared/assets/stores/store';
import {
    FaBagShopping,
    FaShop,
    FaMagnifyingGlass,
    FaPeopleGroup,
    FaLinesLeaning,
    FaRegFileLines

} from "react-icons/fa6";
import Window from '../Window';
import "./styles/transactions.css"
import TransactionLogList from './TransactionLogList';
import { AllMerchantTransactions } from '../../shared/assets/slices/adminSlice';
import CurrencyDisplay from '../../customer/components/CurrencyDisplay';
import NumberFormatter from '../../customer/components/NumberFormatter';
import { TransLogProps } from '../../types/index';

const TransactionsView = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [transactions, setTransactions] = useState<TransLogProps[]>([])
    const [totalTransactions, setTotalTransactions] = useState<number>(0)
    const [totalFailedTrasactions, setTotalFailedTrasactions] = useState<number>(0)
    const [totalSuccessfulTrasactions, setTotalSuccessfulTrasactions] = useState<number>(0)
    const [totalOrdersTrasactions, setTotalOrdersTrasactions] = useState<number>(0)


    useEffect(() => {
        const fetchTransactions = async () => {
            const response = await dispatch(AllMerchantTransactions());
            setTransactions(response.payload.results);
    
            // Calculate the total amount by summing transaction amounts
            const total = response.payload.results.reduce((acc: number, transaction: TransLogProps) => {
                return acc + Number(transaction.amount);
            }, 0);
    
            // Update state with the total amount
            setTotalTransactions(total);

            const totalFailed = response.payload.results.filter((transaction: TransLogProps) => transaction.status === "pending").length;
            setTotalFailedTrasactions(Number(totalFailed))
            const totalSuccessful = response.payload.results.filter((transaction: TransLogProps) => transaction.status === "success").length;
            setTotalSuccessfulTrasactions(Number(totalSuccessful))
            const totalOrders = response.payload.results.length;
            setTotalOrdersTrasactions(Number(totalOrders))
        };
        fetchTransactions();
    }, [dispatch]);

    return (
        <div className='trans_sup_cont'>
            <h2 className='trans_title1'>Transactions</h2>
            <div className='trans_overview'>
                <div className='trans_overview_sec'>
                    <FaBagShopping size={30} color='#EA580C' className='trans_overview_icn' />
                    <div>
                        <p className='trans_overview_desc'>Total Transactions</p>
                        <p className='trans_overview_amt'><CurrencyDisplay amount={Number(totalTransactions)} currency="NGN" /></p>
                    </div>
                </div>
                <div className='trans_overview_sec'>
                    <FaBagShopping size={30} color='#27AE60' className='trans_overview_icn' />
                    <div>
                        <p className='trans_overview_desc'>Successful Transactions</p>
                        <p className='trans_overview_amt'><NumberFormatter amount={totalSuccessfulTrasactions}/></p>
                    </div>
                </div>
                <div className='trans_overview_sec'>
                    <FaBagShopping size={30} color='#EB5757' className='trans_overview_icn' />
                    <div>
                        <p className='trans_overview_desc'>Failed Transactions</p>
                        <p className='trans_overview_amt'><NumberFormatter amount={totalFailedTrasactions}/></p>
                    </div>
                </div>
                <div className='trans_overview_sec'>
                    <FaLinesLeaning size={30} color='#9333EA' className='trans_overview_icn' />
                    <div>
                        <p className='trans_overview_desc'>Total Orders</p>
                        <p className='trans_overview_amt'><NumberFormatter amount={totalOrdersTrasactions}/></p>
                    </div>
                </div>
            </div>
            <div className='trans_trans_cont'>
                {/* <div className='trans_trans_hd'>
                <p className='trans_trans_hd_txt'>Customer</p>
                <p className='trans_trans_hd_txt'>Amount</p>
                <p className='trans_trans_hd_txt'>Merchant</p>
                <p className='trans_trans_hd_txt'>Status</p>
                <p className='trans_trans_hd_txt'></p>
            </div> */}
                <div>
                    <TransactionLogList transactions={transactions} />
                </div>
            </div>

        </div>
    )
}
const Transactions = () => {
    const content = {
        currentView: <TransactionsView />,
        activeId: 2
    }
    return (
        <Window currentView={content.currentView} activeId={content.activeId} />
    )
}
export default Transactions