import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { UserProps } from '../../types/index';

interface UserDetailsModalProps {
    show: boolean;
    onHide: () => void;
    user: UserProps | null;
}

const UserDetailsModal: React.FC<UserDetailsModalProps> = ({ show, onHide, user }) => {
    if (!user) return null;

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{user.user_type} Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="merchant-details">
                    {user.business_name ?(<p><strong>Business Name:</strong> {user.business_name}</p>): null}
                    <p><strong>First Name:</strong> {user.first_name || 'N/A'}</p>
                    <p><strong>Last Name:</strong> {user.last_name || 'N/A'}</p>
                    <p><strong>Country:</strong> {user.country || 'N/A'}</p>
                    <p><strong>Email:</strong> {user.email || 'N/A'}</p>
                    <p><strong>Phone:</strong> {user.phone_number || 'N/A'}</p>
                    <p><strong>Joined On:</strong> {new Date(user.created_at).toLocaleDateString() || 'N/A'}</p>
                    <p><strong>User Type:</strong> {user.user_type || 'N/A'}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserDetailsModal;
