// signupSlice.js
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { env } from '../environment/envSelector'
import { adminState } from '../../../types/index';

const initialState: adminState = {
    password: '',
    confirmPassword: '',
    email: '',
    emailOtp: '',
    phoneOtp: '',
    country: '',
    isAuthenticated: false,
    getAdmin: null,
    getAllUsers: null,
  };


  export const adminLogin = createAsyncThunk('admin/login', async (credentials: { email: string; password: string }) => {
    const response = await fetch(`${env.login}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(credentials),
    });
    return response.json();
  });

  export const AllMerchantTransactions = createAsyncThunk('admin/transactions', async () => {
    const response = await fetch(`${env.AllMerchantTransactions}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    return response.json();
  });

  // getAllWallet
  export const AllWallets = createAsyncThunk('admin/wallets', async () => {
    const response = await fetch(`${env.getAllWallet}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        // 'Authorization': `Bearer ${token}`,
      },
    });
    return response.json();
  });

  export const GetAllUsers = createAsyncThunk('admin/all-users', async (token: string) => {
    const response = await fetch(`${env.getAllUser}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.json();
  });

  export const adminLogout = createAsyncThunk('admin/logout', async (token: string) => {
    const response = await fetch(`${env.logoutUser}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.json();
  });

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setCountry: (state, action) => {
            state.country = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        setConfirmPassword: (state, action) => {
            state.confirmPassword = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setEmailOtp: (state, action) => {
            state.emailOtp = action.payload;
        },
        setPhoneOtp: (state, action) => {
            state.phoneOtp = action.payload;
        },
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload
        },
        setGetAdmin: (state, action) => {
            state.getAdmin = action.payload
        },
        resetAuthState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
          // Add cases for other async actions
          .addCase(adminLogin.fulfilled, (state, action: PayloadAction<adminState>) => {
            state.getAdmin = action.payload;
            state.isAuthenticated = true;
          })
          .addCase(GetAllUsers.fulfilled, (state, action) => {
            state.getAllUsers = action.payload;
          })
          .addCase(adminLogout.fulfilled, (state, action: PayloadAction<adminState>) => {
            state.isAuthenticated = false;
          })
        }
});

export const { setCountry, setPassword, setEmail, setEmailOtp, setPhoneOtp, setIsAuthenticated, resetAuthState, setGetAdmin, setConfirmPassword } = adminSlice.actions;

export default adminSlice.reducer;
